import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/fright-night-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1985 vampire movie, Fright Night"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 32</h1>
                    <h2>Fright Night</h2>
                    <h3>January 22, 2024</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Tom Holland // <b>Starring:</b> William Ragsdale &amp; Chris Sarandon</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/32-Fright-Night-wguest-Peaches-Christ-e2ekl6n/a-aas2ucd" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast episode 32, Fright Night with guest Peaches Christ"></iframe>
                        </div>
                        <p><Link to="/transcripts/fright-night">Read the episode transcript</Link></p>
                        <p>Bryan and Dave are joined this week by drag performer extraordinaire, Peaches Christ, to talk about the arch-80's vampire classic from 1985, Fright Night, starring William Ragsdale, Roddy McDowall, and Chris Sarandon (making his second appearance on the pod). It's an awesome movie that could have been even better for reasons we get deep in the weeds over.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/x-ray">Next Episode</Link></li>
                        <li><Link to="/episodes/new-years-evil">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)